<template>
  <navbar
    position="fixed"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link
        v-popover:popover1
        class="navbar-brand"
        to="/"
      >
        <img 
          src="img/icons/CurtLogo.png" 
          alt="" 
          width="50" 
          height="50"
        >
      </router-link>
      <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">
          The Big Curt Charitable Foundation
        </div>
      </el-popover>
    </template>
    <template slot="navbar-menu">
      <router-link
        v-popover:popover1
        class="nav-link"
        to="/camp"
      >
        Big Curt's Camp
      </router-link>

      <router-link
        v-popover:popover1
        class="nav-link"
        to="/team"
      >
        Team
      </router-link>

      <router-link
        v-popover:popover1
        class="nav-link"
        to="/about"
      >
        About
      </router-link>

      <li class="nav-item">
        <router-link
          class="nav-link btn btn-primary"
          to="/donate"
          target="_blank"
        >
          <p>Donate</p>
        </router-link>
      </li>
    </template>
  </navbar>
</template>

<script>
  import { DropDown, Navbar, NavLink } from '@/components';
  import { Popover } from 'element-ui'
  export default {
    name: 'MainNavbar',
    components: {
      // DropDown,
      Navbar,
      // NavLink,
      [Popover.name]: Popover
    },
    props: {
      transparent: Boolean,
      colorOnScroll: Number,
    }
  }
</script>

<style scoped>

</style>
