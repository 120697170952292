<template>
    <section class="donations-page container">
      <div class="donations-header">
        <h1>Support Our Mission</h1>
        <p>Your contribution helps make a difference in our community. Choose an amount and make an impact today!</p>
      </div>
  
      <div class="donation-amounts">
        <h2>Select Donation Amount</h2>
        <div class="amount-options">
          <button v-for="option in presetAmounts" :key="option" :class="{ active: selectedAmount === option }" @click="selectAmount(option)">
            ${{ option }}
          </button>
          <input type="number" v-model="customAmount" placeholder="Enter Custom Amount" @input="selectCustomAmount" />
        </div>
      </div>
  
      <div class="payment-form">
        <h2>Enter Your Details</h2>
        <form @submit.prevent="handleDonation">
          <input type="text" v-model="donorName" placeholder="Your Name" required />
          <input type="email" v-model="donorEmail" placeholder="Email Address" required />
          <input type="text" v-model="cardNumber" placeholder="Card Number" required />
          <input type="text" v-model="expiryDate" placeholder="MM/YY" required />
          <input type="text" v-model="cvv" placeholder="CVV" required />
          <button type="submit">Donate ${{ selectedAmount || customAmount }}</button>
        </form>
      </div>
  
      <div class="impact-info">
        <h2>Your Impact</h2>
        <p>Your donations help fund our community programs, providing essential resources and support for those in need.</p>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "DonationsPage",
    data() {
      return {
        presetAmounts: [25, 50, 100, 250, 500],
        selectedAmount: null,
        customAmount: '',
        donorName: '',
        donorEmail: '',
        cardNumber: '',
        expiryDate: '',
        cvv: '',
      };
    },
    methods: {
      selectAmount(amount) {
        this.selectedAmount = amount;
        this.customAmount = '';
      },
      selectCustomAmount() {
        this.selectedAmount = null;
      },
      handleDonation() {
        // Placeholder function to handle the donation submission
        alert(`Thank you for donating $${this.selectedAmount || this.customAmount}!`);
        // Here, you would integrate with a payment processing API.
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>

  
  .donations-page {
    padding: 2rem;
    color: #002244;
  
    .donations-header, .impact-info {
      text-align: center;
      margin-bottom: 2rem;
    }
  
    h1, h2 {
      color: #C4C6CA;
    }
  
    .donation-amounts {
      margin-bottom: 2rem;
      .amount-options {
        display: flex;
        gap: 10px;
        margin-top: 1rem;
  
        button {
          padding: 0.75rem 1.5rem;
          background-color: #ffffff;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          color: white;
          font-weight: bold;
          transition: background-color 0.3s;
  
          &.active, &:hover {
            background-color: darken(#ffffff, 10%);
          }
        }
  
        input[type="number"] {
          padding: 0.75rem;
          border-radius: 8px;
          border: 1px solid #C4C6CA;
          flex: 1;
        }
      }
    }
  
    .payment-form {
      form {
        display: grid;
        gap: 1rem;
        margin-bottom: 2rem;
  
        input {
          padding: 0.75rem;
          border-radius: 8px;
          border: 1px solid #C4C6CA;
        }
  
        button {
          padding: 0.75rem;
          background-color: #002244;
          color: white;
          font-weight: bold;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: darken(#002244, 10%);
          }
        }
      }
    }
  
    .impact-info p {
      color: #69BE28;
    }
  }
  </style>
  