<template>
  <div class="wrapper blog-post">
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg24.jpg')"
      />
      <div class="content-center">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Coming Soon
            </h2>
            <h4>Individual Team Member Pages.</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="button-container">
              <a
                href="#pablo"
                class="btn btn-primary btn-round btn-lg"
              >
                <i class="now-ui-icons text_align-left" /> Read Article
              </a>
              <a
                href="#pablo"
                class="btn btn-icon btn-lg btn-twitter btn-round"
              >
                <i class="fab fa-twitter" />
              </a>
              <a
                href="#pablo"
                class="btn btn-icon btn-lg btn-facebook btn-round"
              >
                <i class="fab fa-facebook-square" />
              </a>
              <a
                href="#pablo"
                class="btn btn-icon btn-lg btn-google btn-round"
              >
                <i class="fab fa-google" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Lorem ipsum dolor sit amet consectetur.
              </h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, incidunt exercitationem non iste id expedita quaerat alias sunt?
                <br>
                <br> Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa iusto et ipsa debitis officia commodi dignissimos dolorum reiciendis, numquam veritatis quam error eaque asperiores! Reiciendis quidem architecto culpa incidunt velit?
              </p>
              <p class="blockquote blockquote-primary">
                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Blanditiis, hic!"
                <br>
                <br>
                <small>
                  First Last, Name.
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-blog col-md-10 ml-auto mr-auto">
        <div class="container">
          <div class="section-story-overview">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="image-container image-left"
                  style="background-image: url('img/bg35.jpg')"
                >
                  <!-- First image on the left side -->
                  <p class="blockquote blockquote-primary">
"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quo, natus magni repudiandae officia aperiam ad!"
                    <br>
                    <br>
                    <small>-NOAA</small>
                  </p>
                </div>
                <!-- Second image on the left side of the article -->
                <div
                  class="image-container image-left-bottom"
                  style="background-image: url('img/bg29.jpg')"
                />
              </div>
              <div class="col-md-5">
                <!-- First image on the right side, above the article -->
                <div
                  class="image-container image-right"
                  style="background-image: url('img/project16.jpg')"
                />
                <h3>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Exercitationem, consectetur!</h3>
                <p>
Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque harum officiis aspernatur quod? Iusto ullam laboriosam modi corrupti quo sed aliquid asperiores, obcaecati exercitationem assumenda? Laboriosam libero tempore ad praesentium perferendis, mollitia expedita. Deserunt, quam.
                </p>
                <p>
Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro asperiores corporis dolor soluta dolorem esse est sit fugiat laboriosam vitae? Recusandae odit omnis ut corrupti laboriosam commodi molestiae placeat necessitatibus, ex, unde porro, minima et. Exercitationem, consequuntur beatae fuga sit voluptatum odio explicabo consectetur quam totam earum autem dolorum eligendi!
                </p>
                <p>
Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloremque consequatur cum sunt exercitationem modi, laboriosam molestiae, provident vel autem, quos minus dicta nihil. Quisquam dicta explicabo expedita consequatur aperiam itaque nobis distinctio repellendus sapiente sunt eaque illum nisi eum incidunt, ab et ex aliquid quod eos! Id accusamus nihil numquam doloremque, reiciendis itaque inventore delectus?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Lorem ipsum dolor sit.
              </h3>
              <p>
Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eos molestiae quae sapiente dicta saepe deserunt, in corrupti incidunt veniam totam! Minus maiores pariatur totam voluptatibus nostrum mollitia deleniti expedita quidem magnam? Quidem, enim aspernatur? Nobis ipsum autem officia adipisci nostrum minus maxime quos repellat at debitis expedita harum, et dolorum reiciendis omnis tenetur! Unde, beatae.
                <br> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, doloribus. Quas, rem ad est libero quia facere ipsum repellat voluptatibus culpa similique!
              </p>
              <p>
Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque sit necessitatibus, quis nisi soluta aut hic alias omnis dolores quidem animi perferendis ab aliquam maiores neque eaque, unde repellat nostrum distinctio assumenda nesciunt? Labore nostrum mollitia consequuntur voluptatum temporibus ipsam necessitatibus tempora iste quidem totam et beatae eum, pariatur ipsum.
              </p>
              <p>
Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro voluptas reprehenderit corrupti a magni sed. Officia in velit illo, officiis corrupti, odio optio rem facilis voluptatibus accusamus, dignissimos repudiandae ratione.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="section">
        <div class="container">
          <div class="col-md-12">
            <h2 class="title text-center">
              Similar Stories
            </h2>
            <br>
            <div
              id="blogs-1"
              class="blogs-1"
            >
              <div class="row">
                <div class="col-md-10 ml-auto mr-auto">
                  <card
                    type="blog"
                    plain
                  >
                    <template slot="raw-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="card-image">
                            <img
                              class="img img-raised rounded"
                              src="img/examples/card-blog4.jpg"
                              alt=""
                            >
                          </div>
                        </div>
                        <div class="col-md-7">
                          <h6 class="category text-info">
                            Enterprise
                          </h6>
                          <h3 class="card-title">
                            <a href="#pablo">Warner Music Group buys concert discovery
                              service Songkick</a>
                          </h3>
                          <p class="card-description">
                            Warner Music Group announced today it’s acquiring the selected
                            assets of the music platform Songkick, including its app for
                            finding concerts and the company’s trademark.
                          </p>
                          <p class="author">
                            by
                            <a href="#pablo">
                              <b>Sarah Perez</b>
                            </a>, 2 days ago
                          </p>
                        </div>
                      </div>
                    </template>
                  </card>
                  <card
                    type="blog"
                    plain
                  >
                    <div class="row">
                      <div class="col-md-7">
                        <h6 class="category text-danger">
                          <i class="now-ui-icons now-ui-icons media-2_sound-wave" /> Startup
                        </h6>
                        <h3 class="card-title">
                          <a href="#pablo">Insticator raises $5.2M to help publishers</a>
                        </h3>
                        <p class="card-description">
                          Insticator is announcing that it has raised $5.2 million in Series A
                          funding. The startup allows online publishers to add quizzes, polls
                          and other interactive elements...
                        </p>
                        <p class="author">
                          by
                          <a href="#pablo">
                            <b>Anthony Ha</b>
                          </a>, 5 days ago
                        </p>
                      </div>
                      <div class="col-md-5">
                        <div class="card-image">
                          <img
                            class="img img-raised rounded"
                            src="img/examples/card-blog6.jpg"
                          >
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
  <script>
    import { Card, Button, Comment, Checkbox } from '@/components';
  
    export default {
      name: 'BlogPost',
      bodyClass: 'blog-post',
      components: {
        Card,
        Comment,
        [Button.name]: Button,
        [Checkbox.name]: Checkbox
      },
      data() {
        return {
          form: {
            comment: ''
          },
          comments: [{
            avatar: 'img/james.jpg',
            author: 'Tina Andrew',
            date: '7 minutes ago',
            comment: `<p>Chance too good. God level bars. I'm so proud of @LifeOfDesiigner #1 song in the country. Panda! Don't be scared of the truth because we need to restart the human foundation in truth I stand with the most humility. We are so blessed!</p>
                              <p>All praises and blessings to the families of people who never gave up on dreams. Don't forget, You're Awesome!</p>`,
          }, {
            avatar: 'img/michael.jpg',
            author: 'John Camber',
            date: 'Yesterday',
            comment: `<p>Hello guys, nice to have you on the platform! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                              <p> Don't forget, You're Awesome!</p>`,
            replies: [{
              avatar: 'img/julie.jpg',
              author: 'Tina Andrew',
              date: '2 Days Ago',
              comment: `<p>Hello guys, nice to have you on the platform! There will be a lot of great stuff coming soon. We will keep you posted for the latest news.</p>
                                  <p> Don't forget, You're Awesome!</p>`,
              liked: true,
              likes: 25
            }]
          },
          ]
        }
      }
    }
  </script>
  <style>
  </style>
  